<template>
  <div class="courseInfoWrap">
    <header>
        <el-row class='hedaer-Bg'>
            <el-row>
            <el-col :span="12" class='header-log-Name'>
                    <div class="grid-content header-left-content">
                        <div class='logo-wrap'><img src='../../assets/image/homeImg/logo.png'/></div>
                        <div class='header-left-name' @click="$router.push('/home')">河南省团干部网络教育平台</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content header-right-content">
                        <div @click='pageJumpLogin' v-if="userName==''">登录</div>
                        <div v-else class='userName-wrap'>
                                <el-dropdown placement='bottom'>
                                    <span class="el-dropdown-link">
                                        学员{{userName}}<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                                        <el-dropdown-item @click.native="$router.push('/login')">退出登录</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                </el-col>
            </el-row>
            <navTab></navTab>
            <el-row class='header-courseTitle'>
                <el-col>
                    <div class="title-content">
                        <div>{{courseTitle}}</div>
                    </div>
                </el-col>
            </el-row>
        </el-row>
    </header>
    <div class='course-content'>
        <div class='course-content-cover'>
            <div class="course-content-cover-left">
                <div class='course-content-title' v-show='!courseCover_url'>{{courseTitle}}</div>
                <div class='course-content-title' v-show='courseCover_url'>
                    <img :src="courseCover_url" alt="">
                </div>
            </div>
            <div class="course-content-cover-right">
                <!-- 顶部 -->
                <div class='course-content-otherInfo'>
                    <div class='teacherAndadress'>
                        <div>主讲人：<span>{{courseTeacher}}</span></div>
                    </div>
                    <div class="submitWork" @click='jumpHomeWorkPage()'>
                        <div class='imgWrap'><img src="../../assets/image/courseInfo/task.png" alt=""></div>
                        <div>作业提交</div>
                    </div>
                </div>
                <!-- 进度 -->
                <div  class="notLiveJoinBox">
                    <div class="progess">进度：<el-progress :percentage="processRate"></el-progress></div>
                    <div style='curse:point' class="listenClassBtn" @click='listenNotLiveBtn()'>立即听课</div>
                </div>
            </div>
        </div>
        <div class='course-content-info'>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="课程介绍" name="first">
                    <el-row class='course-content-introduce'>
                        <!-- <el-col>
                            <div class='teacher-info introduce-item introduce-title'>课程班主任</div>
                            <div class='teacher-name introduce-item' v-html='courseHeaderTeacher'></div>
                        </el-col> -->
                        <el-col>
                            <div class='course-info introduce-item introduce-title'>课程详情</div>
                            <div class='course-img introduce-item' v-html='courseIntro'></div>
                        </el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import footerCom from '@/components/footer.vue'
import navTab from '@/components/navTab.vue'
export default {
  name: '',
  components: {
    footerCom,
    navTab
  },
  props: {},
  watch: {},
  data () {
    return {
      activeName: 'first',
      userName: '',
      activeNames: [],
      courseTitle: '',
      courseId: '',
      studyDateDesc: '',
      courseTeacher: '',
      courseAddr: '',
      courseType: '',
      courseHeaderTeacher: '',
      courseHeaderLink: '',
      courseIntro: '',
      courseCover_url: '',
      isSignin: 0,
      channelId: '3772942',
      id: '',
    }
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event)
    },
    handleChange (val) {
      console.log(val)
    },
    // 跳转登录
    pageJumpLogin () {
      this.$router.push('/login')
    },
    // 进入录播课
    listenNotLiveBtn () {
      this.$router.push(`/playback/${this.$route.params.courseId}`)
    },
    // 跳转我的作业页面
    jumpHomeWorkPage () {
      if (this.courseId) {
        this.$router.push(`/myHomework?courseId=${this.id}`)
      }
    },
    // 下载对应课件
    downloadChapterAttach (url) {
      if (url) {
        window.open(url)
      } else {
        this.$message({
          type: 'warning',
          message: '暂无课件可以下载！'
        })
      }
    },
    // 获取课程详情
    getCourseDetail () {
      this.$axios.get(this.$apiUrl.loadChapterCourseDetail, {params: {id: this.$route.params.courseId}}).then(res => {
        let resData = res.data.data
        console.log(resData)
        this.id = resData.id
        let {chapterTitle, chapterTeacher, courseAddr, courseHeaderTeacher, courseHeaderLink, chapterIntro, studyDateDesc, id, courseType, chapterCoverUrl, processRate} = resData.chapterList[0]
        this.courseTitle = chapterTitle // 课程标题
        this.studyDateDesc = studyDateDesc
        this.courseTeacher = chapterTeacher
        this.courseAddr = courseAddr
        this.courseHeaderTeacher = courseHeaderTeacher
        this.courseHeaderLink = courseHeaderLink
        this.courseIntro = chapterIntro
        this.courseId = id
        this.courseType = courseType
        this.courseCover_url = chapterCoverUrl
        this.processRate = processRate
      }).catch(err => {
        console.log(err)
      })
    },
    // 获取local中用户信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo') ? JSON.parse(localStorage.getItem('studentInfo')) : ''
      console.log(userinfo)
      if (userinfo) {
        let {stuName} = userinfo
        this.userName = stuName
      }
    },
  },
  mounted () {
    this.getUserInfo()
    this.getCourseDetail()
  }
}
</script>

<style scoped lang="scss">
.courseInfoWrap{
    position: relative;
    min-height: 100vh;
    header {
        background: url("../../assets/image/homeImg/headerBg.png");
        background-size: 100% 100%;
        height: 344px;
    }
    .hedaer-Bg{
        /* background: url('../../assets/image/homeImg/headerBg.png'); */
        background-size:100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        height: 344px;
        width: 1440px;
        margin: 0 auto;
        padding: 0 100px;
        .header-log-Name{
            height: 42px;
            line-height: 42px;
            .header-left-content{
                font-size: 20px;
                color: white;
                font-weight: 800;
                display: flex;
                justify-content: start;
                .logo-wrap{
                    padding: 0 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        height: 33px;
                        width: 33px;
                    }
                }
                .header-left-name{
                    cursor: pointer;
                }
            }
        }
        .header-right-content{
            font-size: 14px;
            color: white;
            font-weight: 600;
            display: flex;
            justify-content: end;
            position: relative;
            .userName-wrap{
                height: 42px;
                align-items: center;
                display: flex;
                justify-content: center;
            }
            ::v-deep .el-dropdown{
                font-size: 14px;
                color: #4774DF;
                background-color: #fff;
                height: 30px;
                line-height: 30px;
                border-radius: 6px 6px 6px 6px;
                opacity: 1;
                padding: 0px 20px;
            }
        }
        .header-courseTitle{
           padding: 30px 96px 40px 0px;
           .title-content{
                font-size: 28px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 42px;
                margin-bottom: 30px
           }
           .courseNum-content{
                font-size: 18px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 42px;
           }
        }
    }
    header{
        position: relative;
        /* margin-bottom: 70px; */
    }
}
.course-content{
    /* width: 90%; */
    padding: 0 100px;
    padding-bottom:500px;
    width: 1440px;
    margin: 0 auto;
    margin-top: -58px;
    .handle-content{
        width: 1120px;
        margin: 0 auto 60px;
        height: 116px;
        background: #FFFFFF;
        box-shadow: 0px 3px 15px 1px rgba(0,0,0,0.12);
        border-radius: 10px 10px 10px 10px;
        .handle-content-col{
            display: flex;
            justify-content: center
        }
        .handle-content-item{
            width: 100px;
            height: 112px;
            font-size: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;
            cursor: pointer;
            img{
                width: 36px;
                height: 36px;
                margin-bottom: 14px;
            }
            >div{
                cursor: pointer;
            }
        }
        .handle-content-item:hover{
            transform: scale(1.01)
        }
    }
    .course-content-cover{
        margin-bottom: 55px;
        display: flex;
    }
    .course-content-cover-left{
        width: 384px;
        height: 255px;
        background: url('../../assets/tuanBg.png') no-repeat;
        background-size:100% 100%;
        border-radius: 10px;
        font-size: 27px;
        color: #FFFFFF;
        line-height: 205px;
        text-align: center;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;

      img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
        .course-content-title{
            line-height: 30px;
            border-radius: 10px;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 100%;
            //padding: 0px 30px;
            border-radius: 10px;
            justify-content: center;
            align-content: center;
            color: #ffff;
        }
    }
    .course-content-cover-right{
        flex: 1;
        font-size: 22px;
        margin-left: 25px;
        height: 205px;
        font-weight: 400;
        color: #000000;
        padding: 10px 0px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        display: flex;
        flex-direction: column;
        position: relative;
        /* align-items: center; */
        justify-content: center;
        padding-top: 100px;
        .course-content-otherInfo{
            display: flex;
            justify-content: space-between;
            width: 701px;
            .teacherAndadress{
                flex: 1;
                div{
                    margin-bottom: 20px;
                }
            }
            .submitWork{
                width: 100px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                cursor: pointer;
                .imgWrap{
                    width: 60px;
                    height: 60px;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.16);
                    opacity: 1;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 10px;
                }
            }
        }
        >div{
            padding: 4px 0px;
        }
        >div.listenCourse-btn-wrap{
            padding: 0px;
            position: absolute;
            bottom: 20px;
            .listenCourse-btn{
                width: 279px;
                height: 51px;
                background: #FF6F00;
                border-radius: 5px 5px 5px 5px;
                opacity: 1;
                border: 1px solid #FF6F00;
                font-size: 23px;
                font-weight: bold;
                color: #fff
            }
        }
        .notLiveJoinBox{
            display: flex;
            padding-top: 17px;
            .progess{
                display: flex;
                justify-content: center;
                align-items: center;
                ::v-deep .el-progress{
                    width: 300px;
                    line-height: normal;
                }
            }
            .listenClassBtn{
                width: 300px;
                height: 50px;
                background: #FF6F00;
                font-size: 21px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                line-height: 50px;
                margin-left: 50px;
                cursor: pointer;
            }
        }

    }
    .course-content-info{
        background: #FFFFFF;
        box-shadow: 0px 3px 15px 1px rgba(0,0,0,0.12);
        border-radius: 4px 4px 4px 4px;
        padding: 0px 50px 30px;
        ::v-deep .el-tabs__nav-scroll{
            height: 70px;
            line-height: 70px;
            padding: 0 15px;
        }
        ::v-deep .el-tabs__item{
            width: 150px;
            font-size: 20px;
            font-weight: 400;
            text-align: center
        }
        ::v-deep .el-collapse{
            border: 0px solid #fff
        }
        ::v-deep .el-collapse-item__header{
            padding: 30px 41px 30px 39px;
        }
        ::v-deep .el-divider--horizontal{
            margin: 0px
        }
        .course-content-introduce{
            padding: 0px 50px 0px 50px;
            .introduce-item{
                padding-left:20px;
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 20px;
                border-left:5px solid #fff;
                white-space: pre-wrap;
            }
            .course-img{
                width: 100%;
                ::v-deep img{
                    width: 100%;
                }
            }
            .introduce-title{
                font-size: 20px;
                font-weight: bold;
                border-left:5px solid #1C4ECC;
            }
        }
        .course-directory{
            .course-intro-left{
                padding: 22px 0px;
                .course-intro-left-date{
                    font-size: 16px;
                    color: #1C4ECC;
                    margin-bottom: 15px
                }
                .course-intro-left-name{
                    font-size: 18px;
                    color: #000000;
                     margin-bottom: 17px
                }
                .course-intro-left-teacher{
                    font-size: 16px;
                    color: #525455;
                    display: flex;
                    .studyProgess{
                        margin-left:81px;
                         .progessNum{
                            color: #FF6767;
                            margin: 0 10px;
                         }
                    }
                }
            }
            .course-intro-center{
                font-size: 14px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #525455;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 30px;
                >div{
                    padding: 10px
                }
            }
            .course-intro{
                padding: 0px 30px 0px 35px;
                background: #DBE8FF;
                .handle-wrap{
                    display: flex;
                    flex-direction: row;
                    /* flex-wrap: nowrap; */
                    justify-content:center;
                    align-items: center;
                }
                .play-wrap{
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                    img{
                        width: 24px;
                        height: 24px;
                    }
                    .play{
                       font-size: 14px;
                        color: #FF6F00;
                    }
                    .playback{
                       font-size: 14px;
                        color: #4774DF;
                    }
                }
                .download-wrap{
                    margin-left: 42px;
                    width: 60px;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img{
                        width: 24px;
                        height: 24px;
                    }
                    .noDownloadstr{
                        font-size: 14px;
                        color: #b2b0b0;
                    }
                }
            }
        }
    }
}
::v-deep .el-collapse-item__header{
    font-size: 18px;
    color: #000000;
}
.el-collapse-item{
    ::v-deep .el-collapse-item__content{
        padding-bottom: 0px;
    }
}
/depp/ .el-tabs__active-bar{
    width: 80px;
    height: 10px;
    transform: translateX(22px);
}
::v-deep .el-dropdown-menu__item{
    font-size: 32px;
    line-height: 40px;
}
::v-deep .el-dropdown-menu__item {
    font-size: 16px;
    line-height: 20px;
}
</style>
